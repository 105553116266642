import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ButtonOld } from '../common/';
import Form from 'react-bootstrap/Form';

const QUESTIONS = [
  { id: 'content-not-good-enough', label: 'Böckerna jag vill läsa/lyssna på saknas' },
  { id: 'reduce-costs', label: 'Kostnaden är för hög' },
  { id: 'had-technical-issues', label: 'Jag upplever problem med appen' },
  { id: 'try-other-service', label: 'Jag ska prova en annan tjänst istället ' },
  { id: 'only-wanted-to-test', label: 'Jag ville bara ta del av erbjudandet' },
  { id: 'below-expectations', label: 'Tjänsten motsvarade inte mina förväntningar' },
  { id: 'others-no-reason', label: 'Annan anledning' },
];

const CancelSubscriptionModal = ({
  show,
  onClose,
  onCancelSubscription,
  onSubmitWrittenFeedback,
  activeUntil,
}) => {
  const [step, setStep] = useState(1);
  const [reason, setReason] = useState({});
  const [feedback, setFeedback] = useState('');

  const handleClose = () => {
    const reloadPage = step >= 3;

    onClose(reloadPage);

    // Reset form if it is opened up again
    setTimeout(() => {
      setStep(1);
      setReason({});
      setFeedback('');
    }, 100);
  };

  const handleCancelSubscription = async () => {
    if (Object.keys(reason).length) {
      const selectedReason =
        Object.keys(reason)
          .filter((r) => reason[r])
          .join(',') || 'none-selected';
      setStep(2);

      await onCancelSubscription(selectedReason);

      setStep(3);
    } else {
      document.getElementById('cta-chooseOption').style.display = 'block';
    }
  };

  const handleSendFeedback = async () => {
    await onSubmitWrittenFeedback({feedback: feedback, reason: Object.keys(reason)[0]});
    handleClose(true);
  };

  let subtitle = 'Avsluta prenumeration';
  let title = 'Varför vill du säga upp din prenumeration?';
  let body, footer;

  if (step === 1 || step === 2) {
    body = (
      <div style={{ padding: '0 1rem' }}>
        <div style={{ padding: '0 1rem' }}>
          {QUESTIONS.map((props) => (
            <Form.Check
              disabled={step === 2}
              key={props.id}
              {...props}
              type="radio"
              style={{ marginBottom: '6px' }}
              checked={reason[props.id] || false}
              onChange={({ target: { id, checked } }) => setReason({ [id]: checked })}
            />
          ))}
        </div>
      </div>
    );
    footer = (
      <Modal.Footer
        style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
      >
        <p id="cta-chooseOption" style={{ color: 'red', display: 'none' }}>
          <i>Du behöver välja ett alternativ för att avsluta!</i>
        </p>
        <div>
          <ButtonOld style={{ margin: '0.25rem' }} disabled={step === 2} onClick={handleClose}>
            Stanna kvar
          </ButtonOld>
          <ButtonOld
            style={{ margin: '0.25rem' }}
            disabled={step === 2}
            className="white"
            onClick={handleCancelSubscription}
          >
            Avsluta
          </ButtonOld>
        </div>
      </Modal.Footer>
    );
  } else {
    title = 'Din prenumeration är nu avslutad 😢';
    subtitle = null;
    body = (
      <div style={{ padding: '0 1rem' }}>
        {new Date(new Date(activeUntil).toDateString()) > new Date(new Date().toDateString()) ? (
          <h5 style={{ textAlign: 'left', color: '#604077' }}>
            Du kan fortsätta använda Lylli tills{' '}
            <span style={{ display: 'inline-block' }}>{activeUntil}</span>
          </h5>
        ) : (
          <div></div>
        )}
        <p>
          Finns det något vi skulle kunna göra bättre?
          <br />
          Vi är tacksamma för all feedback.
        </p>

        <p>
          Hoppas vi ses snart igen{' '}
          <span aria-label="hjärta" role="img">
            💜
          </span>
          ️ /&nbsp;Lylligänget
        </p>

        <hr />
        <Form.Control
          as="textarea"
          style={{ marginBottom: '6px' }}
          value={feedback}
          onChange={({ target: { value } }) => setFeedback(value)}
        />
      </div>
    );
    footer = (
      <Modal.Footer>
        <ButtonOld className="white" onClick={handleClose}>
          Stäng
        </ButtonOld>
        <ButtonOld onClick={handleSendFeedback}>Skicka feedback</ButtonOld>
      </Modal.Footer>
    );
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <div style={{ margin: '30px 30px 0px 30px' }}>
            {subtitle && <h5 style={{ textAlign: 'left', color: '#604077' }}>{subtitle}</h5>}
            {title && <h2 style={{ textAlign: 'left', marginTop: '10px' }}>{title}</h2>}
          </div>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        {footer}
      </Modal>
    </>
  );
};

export default CancelSubscriptionModal;
