import React from 'react';

import tr from '../../translations';

const PERIOD_SINGULAR = {
  WEEKLY: 'veckan',
  MONTHLY: 'månaden',
  QUARTERLY: 'kvartalet',
  YEARLY: 'året',
};
const PERIOD_PLURAL = {
  WEEKLY: 'veckor',
  MONTHLY: 'månader',
  QUARTERLY: 'kvartal',
  YEARLY: 'år',
};

export const getSubscriptionInfoText = (res) => {
  if (!res) return '';

  const state = res.subscriptionState;
  const [subscriptionType, subscriptionTypeShort] = subscriptionTypeString(res.subscriptionType, res.paymentType);

  if (state === 'GRACE') {
    return (
      <>
        <p>
          Vi har inte lyckats debitera dig för din prenumeration. Uppdatera din betalmetod för att fortsätta läsa med Lylli.
        </p>

        <p className='small'>Prenumerationstyp:</p>
        {res.nextPaymentAmount ?
          <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
        :
          <p>{`${subscriptionType}`}</p>
        }
        {res.nextPaymentAt ?
          <>
            <p className='small'>Nästa betalning sker:</p>
            <p className='red'>{formatDate(res.nextPaymentAt)}</p>
          </>
          :
          <></>
        }
      </>
    )
    }

  if (state === 'TRIAL' || state === 'PAYING') {
    const [subscriptionType, subscriptionTypeShort] = subscriptionTypeString(res.subscriptionType, res.paymentType);

    if (res.nextPaymentAmount) {
      if( state === 'TRIAL') {
        return (
          <>
            {res.nextPaymentInDays ?
              <>
                <p className='small'>Provperiod:</p>
                <p>{res.nextPaymentInDays} {res.nextPaymentInDays === 1 ? "dag kvar" : "dagar kvar"}</p>
              </> :
              <></>
            }
            <p className='small'>Prenumerationstyp:</p>
              <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
            {res.nextPaymentAt ?
              <>
                <p className='small'>Nästa betalning sker:</p>
                <p>{formatDate(res.nextPaymentAt)}</p>
              </>
              :
              <></>
            }
          </>
        );
      }
      return (
        <>
            <p className='small'>Prenumerationstyp:</p>
              <p>{`${subscriptionType} ${priceString(res.nextPaymentAmount)}/${subscriptionTypeShort}`}</p>
            {res.nextPaymentAt ?
              <>
                <p className='small'>Nästa betalning sker:</p>
                <p>{formatDate(res.nextPaymentAt)}</p>
              </>
              :
              <></>
            }
        </>
      );
    }
    return (
      <>
        <p className='small'>Prenumerationstyp:</p>
        <p>{`${subscriptionType}`}</p>
        {res.nextPaymentAt ?
          <>
            <p className='small'>Nästa betalning sker:</p>
            <p>{formatDate(res.nextPaymentAt)}</p>
          </>
          :
          <></>
        }
      </>
    );
  }

  if (state === 'LEAD') {
    return (
      <>
        <p className='small'>Prenumerationstyp:</p>
        <p>Ej aktiverad</p>
        <p>Lägg till en betalmetod och läs med Lylli gratis i <span style={{display:'inline-block'}}>{res.trialInDays} dagar</span></p>
      </>
    );
  }

  if (state === 'CHURN') {
    if (res.offer) {
      const subscriptionOffer = subscriptionOfferString(res.offer)
      return (
        <>
          <p className='small'>Prenumerationstyp:</p>
          <p>Inaktiv</p>
          <p className='small'>Erbjudande:</p>
          <p>{`Lägg till en betalmetod och återaktivera ditt konto för ${priceString(res.offer.nextPaymentAmount, res.offer)} ${subscriptionOffer}`}</p>
        </>
      )
    }
    if (res.activeInDays) {
      return (
        <>
          <p className='small'>Prenumerationstyp:</p>
          <p>Inaktiv</p>
          <p className='small'>Fortsätt använda Lylli i:</p>
          <p>{tr('subscription.activeInDays', res.activeInDays)}</p>
        </>
      );
    }
    return (
      <>
        <p className='small'>Prenumerationstyp:</p>
        <p>Inaktiv</p>
      </>
    );
  }
  return '';

  function subscriptionTypeString(type, payType) {
    const subscriptionTypes = {
      'WEEKLY': 'Veckoprenumeration',
      'MONTHLY': 'Månadsprenumeration',
      'QUARTERLY': 'Kvartalsprenumeration',
      'YEARLY': 'Årsprenumeration'
    }

    const subscriptionTypeShort = {
      'WEEKLY': 'v',
      'MONTHLY': 'mån',
      'QUARTERLY': 'kvartal',
      'YEARLY': 'år'
    }
    return [subscriptionTypes[type] + (payType === "APPLE" ? " via Apple": ""), subscriptionTypeShort[type]] || ['prenumeration', ''];
  }

  function subscriptionOfferString(offer) {
    if (offer.discountPeriodCount === 1) {
      return `första ${PERIOD_SINGULAR[offer.subscriptionType]}`
    }
    return `i ${offer.discountPeriodCount} ${PERIOD_PLURAL[offer.subscriptionType]}`;
  }

  function priceString(string, offer = null) {
    let perPeriod = '';
    if (offer?.discountPeriodCount > 1) {
      perPeriod = '/' + PERIOD_SINGULAR[offer.subscriptionType];
    }
    return parseInt(string.replace(/\D/g, '')) / 10 + ' kr' + perPeriod;
  }

  function formatDate(d) {
    const date = new Date(d);
    const months = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];
    return (parseInt(date.getDate()) + " " + months[date.getMonth()] + " " + parseInt(date.getFullYear()));

  }

};
