import InputGroup from 'react-bootstrap/InputGroup';
import FormCheck from 'react-bootstrap/FormCheck';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import React from 'react';

export const ChangeSubscriptionPlans = ({styling, type, setType, currentType}) => {
    return (
        <InputGroup className="mb-3">
            <FormCheck className={type === '1' ? "change-subscription current": styling}>
                <FormCheckLabel htmlFor="month" className="typelabel">Månad</FormCheckLabel>
                <br/>
                <FormCheckLabel htmlFor="month" className="sublabel">{currentType === 'MONTHLY' ? ' ': '89 kr'}</FormCheckLabel>
                <FormCheckInput
                    type="radio"
                    id="month"
                    name="group"
                    label="Månad
                    89 kr"
                    onChange={e => setType('1')}
                    checked={type === '1'}
                    className="change-subscription-radio"
                />
            </FormCheck>
                <p className='small  change-subscription-save-cta'>
                    <span role='img' aria-label="money mouth face">&#129297;</span> Spara 35%
                </p>
            <FormCheck className={type === '12' ? "change-subscription current": styling}>
                <FormCheckLabel htmlFor="year" className="typelabel">År</FormCheckLabel>
                <br/>
                <FormCheckLabel htmlFor="year" className="sublabel">{currentType === 'YEARLY' ? ' ' : '695 kr'}</FormCheckLabel>
                <FormCheckInput
                    type="radio"
                    id="year"
                    name="group"
                    onChange={e => setType('12')}
                    checked={type === '12'}
                    className="change-subscription-radio"
                />
            </FormCheck>
          </InputGroup>
    )
}