import React, {useEffect, useState} from 'react';
import { ChangeSubscriptionPlans } from '../ChangeSubscriptionPlans';
import {BaseModal} from './index';
import {updateSubscription} from "../../../lib/api";
import {getSessionId} from "../../../lib/storage";

const ChangeSubscriptionModal = ({current, show, onClose}) => {

  const [type, setType] = useState('0');
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setType(current.subscriptionType === 'YEARLY' ? '12' : '1');
  }, [current]);

  const handleSave = async (evt) => {
    evt.preventDefault();

    try {
      setWaiting(true);

      const data = {
        "periodCount": type,
        "periodUnit": "months"
      }
      await updateSubscription(getSessionId(), data)

      onClose(true);
    } catch (ex) {
      console.error(ex)
      alert(ex.message);
    } finally {
      setWaiting(false);
      alert(`Nu är din prenumeration uppdaterat till en ${type === '1' ? 'månadsprenumeration' : 'årsprenumeration'}`);
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <BaseModal
      title={`Byt prenumeration`}
      show={show}
      waiting={waiting}
      disabled={type === (current.subscriptionType === 'YEARLY' ? '12' : '1')}
      onClose={handleClose}
      onSave={handleSave}
    >
      <ChangeSubscriptionPlans styling={"change-subscription grey"} type={type} setType={setType} currentType={current.subscriptionType}/>

    </BaseModal>
  );
};

export default ChangeSubscriptionModal;