import React from 'react';

const AppleSubscriptionPane = ({ className, lg, md }) => {
  return (
    <div>
      <p>Laddar prenumerationsinfo...</p>
    </div>
  );
};

/* AppleSubscriptionPane.propTypes = {
  className: PropTypes.string,
  lg: PropTypes.any.isRequired,
  md: PropTypes.any.isRequired,
}; */

export default AppleSubscriptionPane;
