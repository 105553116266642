import React from 'react';
import { Button } from '../../../components/common';

export default function SubmitButton({ processing, error, children, disabled }) {
  return (
    <Button small center bg="green" type="submit" disabled={processing || disabled}>
      {processing ? 'Processing...' : children}
    </Button>
  );
}
